<template>
  <div class="region-header">
    <div class="region-header__wrapper">
      <BreadCrumbs :params="{navigation, color: 'rgba(255,255,255,.58)'}"/>
      <Row>
        <Column class="region-header__half">
          <h1 class="region-header__title">Томская область</h1>
          <p class="region-header__description">
            Томская область — это край, богатый природными чудесами и красотами.
            Здесь много кедровых и сосновых лесов, а местные реки и озера богаты рыбой.
            Ради экологического отдыха сюда приезжают туристы не только из России,
            но и из зарубежных стран.</p>
          <Row>
            <Row class="region-header__anchors"
                 wrap>
              <a :key="anchor.id"
                 class="region-header__anchor"
                 v-for="anchor in anchors"
                 v-on:click.prevent="$handleScroll(anchor.selector, 100)">{{anchor.text}}</a>
            </Row>
          </Row>
        </Column>
      </Row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RegionHeader',
  data() {
    return {
      navigation: [{
        href: '/',
        title: 'Главная',
      }, {
        href: this.$route.path,
        title: 'Oб области',
      }],
      anchors: [{
        selector: '#reasons',
        text: 'Причины посетить',
      }, {
        selector: '#facts',
        text: 'Интересные факты',
      }, {
        selector: '#events',
        text: 'Новости и события',
      }],
    };
  },
  mounted() {
    if (this.$route.hash) {
      this.$handleScroll(this.$route.hash, 100);
    }
  },
};
</script>
